import * as React from 'react'
import RecipesApi from '../api/RecipesApi';
import { useAuth } from './AuthenticationContext';

const LOCAL_STORAGE_QUERY_PARAMETERS_KEY = "Images_QueryParameters";
const EXPIRATION_OFFSET_MINUTES = 1;

type ImagesContextType = {
    queryParameters: string|null;
    getAuthenticatedImageUrl: (url: string) => Promise<string>;
}

const ImagesContext = React.createContext<ImagesContextType>({
    queryParameters: null,
    getAuthenticatedImageUrl: async (url: string) => 'Not initialized'
});

const ImagesProvider: React.FunctionComponent<{}> = props => {
    const auth = useAuth();
    const [queryParameters, setQueryParameters] = React.useState<string|null>(localStorage.getItem(LOCAL_STORAGE_QUERY_PARAMETERS_KEY));

    // Clear cached token when signed out
    React.useEffect(() => {
        if(!auth.isAuthenticated) {
            localStorage.removeItem(LOCAL_STORAGE_QUERY_PARAMETERS_KEY);
        }
    }, [auth.isAuthenticated]);

    // Save query parameters to local storage when changed
    React.useEffect(() => {
        if(queryParameters) {
            localStorage.setItem(LOCAL_STORAGE_QUERY_PARAMETERS_KEY, queryParameters);
        } else {
            localStorage.removeItem(LOCAL_STORAGE_QUERY_PARAMETERS_KEY);
        }
    }, [queryParameters]);

    let refreshQueryParametersPromise: Promise<string>|null = null;
    const getQueryParametersAsync = async() => 
    {
        // Check if query parameters needs to be refreshed
        if(isExpired(queryParameters))
        {
            // Check if there is already a promise to fetch it
            if(!refreshQueryParametersPromise) 
            {
                // Create promise which fetches the SAS token
                refreshQueryParametersPromise = new Promise(async (resolve, reject) =>
                {
                    // Get access token
                    const accessToken = await auth.getAccessTokenAsync();
                    if(accessToken.length > 0)
                    {
                        // Get SAS token
                        const sasToken = await RecipesApi.getSasToken(accessToken);
                        if(sasToken) 
                        {
                            resolve(sasToken.queryParameters);
                        }
                    }
                    reject();
                });

                // Unset promise if it fails to get the token
                refreshQueryParametersPromise.catch(() => 
                {
                    refreshQueryParametersPromise = null;
                    setQueryParameters(null);
                });

                // Set sas token to state if successful
                refreshQueryParametersPromise.then(queryParameters => setQueryParameters(queryParameters));
            }

            // Return the refreshed query parameters once it is done
            return await refreshQueryParametersPromise;
        }
        
        return queryParameters;
    };

    const getAuthenticatedImageUrl = async (url: string) => {
        const queryParameters = await getQueryParametersAsync();
        return queryParameters ? `${url}?${queryParameters}` : '';
    };

    return <ImagesContext.Provider value={{
        queryParameters: queryParameters,
        getAuthenticatedImageUrl: getAuthenticatedImageUrl
    }}>
        {props.children}
    </ImagesContext.Provider>
}

function isExpired(queryParameters: string|null): boolean {
    if(queryParameters) {
        const params = new URLSearchParams(queryParameters);
        const expiration = params.get('se');
        return expiration === null || Date.parse(expiration) < Date.now() - (EXPIRATION_OFFSET_MINUTES * 60 * 1000);
    }

    return queryParameters === null;
}

export const useImages = () => React.useContext(ImagesContext);
export default ImagesProvider;