import * as React from 'react'
import './RecipesPage.scss'
import { Dropdown, IDropdownOption, Spinner, SpinnerSize } from '@fluentui/react';
import RecipeCard from './RecipeCard';
import { useRecipes } from '../Contexts/RecipesContext';

const RecipesPage: React.FunctionComponent = () => {
    const [selectedCollection, setSelectedCollection] = React.useState('all')
    const recipesContext = useRecipes();

    const onCollectionDropDownChanged = (event: any, selectedOption: IDropdownOption | undefined) => {
        setSelectedCollection(selectedOption!.key.toString());
    }

    let content = null;
    if(recipesContext.isSyncing && recipesContext.recipes.length === 0 && recipesContext.collections.length <= 1) {
        content = <Spinner size={SpinnerSize.large} label="Loading recipes" labelPosition="right" style={{marginTop: '24px'}} />;
    } else {
        content = (
            <>
                <Dropdown selectedKey={selectedCollection} options={[{key: 'all', text: 'All collections'}].concat(recipesContext.collections.map(c => ({key: c.id, text: c.name})))} onChange={onCollectionDropDownChanged} />
                <div className="recipes-list-container">
                    {recipesContext.recipes.filter(r => selectedCollection === 'all' || selectedCollection === r.collectionId).sort((r1, r2) => r1.title.localeCompare(r2.title)).map(r => <RecipeCard recipe={r!} key={r.id} />)}
                </div>
            </>
        );
    }

    return (
        <div className="recipes-page-container">
            <h1>Recipes</h1>
            {content}
        </div>
    );
}
export default RecipesPage;