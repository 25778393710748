import * as React from 'react';
import './App.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthenticationProvider, { useAuth } from './Contexts/AuthenticationContext';
import AuthenticatedApp from './AuthenticatedApp';
import DefaultApp from './DefaultApp';
import ImagesProvider from './Contexts/ImagesContext';

export class App extends React.Component<{}> {

  render() {
    return (
      <Router>
        <AuthenticationProvider>
          <ImagesProvider>
            <AppContent />
          </ImagesProvider>
        </AuthenticationProvider>
      </Router>
    );
  }
}

const AppContent: React.FunctionComponent = () => {
  const auth = useAuth();

  if (auth.isAuthenticated) {
    return <AuthenticatedApp />
  }
  return <DefaultApp />
};

export default App;
