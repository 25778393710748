import * as React from 'react';
import { ApiCollection } from '../api/ApiCollection';
import { ApiRecipe } from '../api/ApiRecipe';
import RecipesApi from '../api/RecipesApi';
import { useAuth } from './AuthenticationContext';

type RecipesContextValueType = {
    collections: ApiCollection[],
    recipes: ApiRecipe[],
    isSyncing: boolean
}

const RecipesContext = React.createContext<RecipesContextValueType>({
    collections: [],
    recipes: [],
    isSyncing: false
});

const RecipesProvider: React.FunctionComponent<{}> = props => {
    const auth = useAuth();
    const [collections, setCollections] = React.useState<ApiCollection[]>([]);
    const [recipes, setRecipes] = React.useState<ApiRecipe[]>([]);
    const [isSyncing, setIsSyncing] = React.useState<boolean>(false);

    // TODO: This is not fired for isAuthenticated=false right now because the RecipesProvider is inside the AuthenticatedApp scope, so it's removed from tree before this is fired
    // Sync when first signed in
    React.useEffect(() => {
        const abortController = new AbortController();

        if(auth.isAuthenticated) {
            const fetchRecipes = async () => {
                try {
                    setIsSyncing(true);

                    const accessToken = await auth.getAccessTokenAsync();
                    const syncResult = await RecipesApi.sync(accessToken, 0, abortController.signal);
                    
                    if(syncResult) {
                        setCollections(syncResult.modifiedCollections);
                        setRecipes(syncResult.modifiedRecipes);
                    }
                } catch (e) {
                    if(e.name !== 'AbortError') {
                        console.error(`Failed to fetch recipes: ${e.message}`);
                    }
                }
                finally {
                    setIsSyncing(false);
                }
            };

            fetchRecipes();

        } else {
            setRecipes([]);
            setCollections([]);
        }

        // Abort when unsubscribing
        return () => abortController.abort();
    }, [auth, auth.isAuthenticated]);

    return (
        <RecipesContext.Provider value={{
            collections: collections,
            recipes: recipes,
            isSyncing: isSyncing
        }}>
            {props.children}
        </RecipesContext.Provider>
    );
}

export const useRecipes = () => React.useContext(RecipesContext);

export default RecipesProvider;