import * as React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import AboutPage from './AboutPage/AboutPage';
import { useAuth } from './Contexts/AuthenticationContext';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import './DefaultApp.scss'

const DefaultApp: React.FunctionComponent = () => {
    return (
        <Switch>
            <Route path="/about" component={AboutPage} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/terms-and-conditions" component={TermsAndConditions} />
            <Route path="/oauth-callback" render={() => <div>Signing in...</div>} />
            <Route exact path="/" component={Home} />
            <Route render={() => {
            return (<div>Page not found.</div>)
            }} />
        </Switch>
    );
};

const Home: React.FunctionComponent = () => {
  const auth = useAuth();
  const history = useHistory();

  const onSignInClicked = (e: React.MouseEvent) => {
    e.preventDefault();
    if(auth.isAuthenticated) {
      history.push('/recipes');
    } else {
      auth.signIn();
    }
  }

  /* eslint-disable jsx-a11y/anchor-is-valid */
  return (
    <div className="landing-page">
      <div className="container">
        <h1>Recipe Collection</h1>
        <h2>A fast, simple, and beautiful way to manage your recipes</h2>
        <a href="#" className="button" onClick={onSignInClicked}>Sign in</a>
        <a href="//www.microsoft.com/store/apps/9NTDRJCQ2P53" className="button" style={{marginLeft: '16px'}} target="_blank" rel="noopener noreferrer">Download for Windows</a>
      </div>
    </div>
  );
}

export default DefaultApp;