import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

const AboutPage: FunctionComponent = () => {
    return (
        <>
            <h1>About</h1>
            <p>This app helps you collect and organize all your favorite recipes in a single location. If you like the app please consider donating.</p>

            <p><a href="https://www.paypal.com/paypalme/mrjfalk" target="_blank" rel="noopener noreferrer">Donate</a></p>
            <p><Link to="/privacy-policy">Privacy Statement</Link></p>
            <p><Link to="/terms-and-conditions">Terms and conditions</Link></p>
        </>
    );
}

export default AboutPage;