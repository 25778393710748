import React, { useEffect, useState } from 'react'
import * as msal from '@azure/msal-browser'
import { IUser } from '../Models/IUser'

const msalConfig = {
    auth: {
        navigateToLoginRequestUrl: true,
        clientId: process.env.REACT_APP_AZURE_ADB2C_CLIENT_ID!,
        authority: 'https://recipecollectionb2c.b2clogin.com/tfp/recipecollectionb2c.onmicrosoft.com/B2C_1_RecipeCollection_SignUp_SignIn_2',
        redirectUri: process.env.REACT_APP_AZURE_ADB2C_REDIRECT_URI,
        knownAuthorities: ["recipecollectionb2c.b2clogin.com"],
        postLogoutRedirectUri: '/'
    },
    cache: {
        cacheLocation: 'localStorage', // 'sessionStorage' or 'localStorage'
        storeAuthStateInCookie: false
    }
}

const loginRequest = {
    scopes: ['openid', 'https://recipecollectionb2c.onmicrosoft.com/api/RecipeCollection.UserAccess']
}

const accessTokenRedirectRequest = {
    scopes: ['openid', 'https://recipecollectionb2c.onmicrosoft.com/api/RecipeCollection.UserAccess']
}

const msalInstance = new msal.PublicClientApplication(msalConfig);

// Set first account as the active one
if(msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}


// Value type for context
type AuthenticationContextValueType = {
    signIn: () => void,
    signOut: () => void,
    getAccessTokenAsync: () => Promise<string>,
    isAuthenticated: boolean,
    userInfo: IUser|null
}

// Create context with default values
const AuthenticationContext = React.createContext<AuthenticationContextValueType>(
{
    signIn: () => {},
    signOut: () => {},
    getAccessTokenAsync: async () => "",
    isAuthenticated: false,
    userInfo: null
});

const getCurrentUser = function(): IUser|null {
    const account = msalInstance.getActiveAccount();
    if(account && account.idTokenClaims) {
        const claims = account.idTokenClaims as any;
        return {
            name: claims.name
        };
    }
    return null;
}

const AuthenticationProvider: React.FunctionComponent<{}> = props => {
    const [isAuthenticated, setAuthenticated] = useState<boolean>(msalInstance.getActiveAccount() != null);
    const [userInfo, setUserInfo] = useState<IUser|null>(getCurrentUser());

    useEffect(() => {
        msalInstance.handleRedirectPromise().then(response => {
            if(response !== null) {
                // Set active account
                msalInstance.setActiveAccount(response.account);
                
                // Set state
                setAuthenticated(true);
                setUserInfo(getCurrentUser());
            }
        })
        .catch(error => {
            console.error(error);
        });
    })

    // Handle sing in requests
    const signIn = () => {
        if(msalInstance.getAllAccounts().length <= 0) {
            msalInstance.loginRedirect(loginRequest);
        }
    }

    // Handle sign out requests
    const signOut = () => {
        setAuthenticated(false);
        setUserInfo(null);
        msalInstance.logout();
    }

    const getAccessToken = async () => {
        
        let accessToken = '';
        try
        {
            const response = await msalInstance.acquireTokenSilent({scopes: ['https://recipecollectionb2c.onmicrosoft.com/api/RecipeCollection.UserAccess']});
            accessToken = response.accessToken;
        } catch(error) {
            // Check if interaction is required
            if(error.name === 'InteractionRequiredAuthError') {
                await msalInstance.acquireTokenRedirect(accessTokenRedirectRequest)
            }
            console.log(error);
        }
        return accessToken;
    };

    return (
        <AuthenticationContext.Provider value={
            {
                signIn: signIn, 
                signOut: signOut,
                getAccessTokenAsync: getAccessToken,
                isAuthenticated: isAuthenticated,
                userInfo: userInfo
            }
        }>
            {props.children}
        </AuthenticationContext.Provider>
    );
};

export const useAuth = () => React.useContext(AuthenticationContext);
export const signInUrl = 'https://recipecollectionb2c.b2clogin.com/recipecollectionb2c.onmicrosoft.com/B2C_1_RecipeCollection_SignUp_SignIn_2/oauth2/v2.0/authorize?client_id=6b583647-ca17-401d-b6ca-c8eb7e4dbbd6&response_type=id_token+token&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Foauth-callback&response_mode=fragment&scope=https%3A%2F%2Frecipecollectionb2c.onmicrosoft.com%2Fapi%2FRecipeCollection.UserAccess%20openid%20offline_access&state=arbitrary_data_you_can_receive_in_the_response&nonce=12345&prompt=login';

export default AuthenticationProvider;