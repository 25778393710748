import React from 'react';

const PrivacyPolicy = () => {
    return (
        <>
            <h1>Privacy policy</h1>
            <p>Thanks for using Recipe Collection (“application”, “service”, “we”, “us”, or “our”). We are commited to protecting your rights to privacy. This privacy policy applies to all our mobile applications and related servies, sales, and marketing.</p>
            
            <h2>1. What personal data do we collect?</h2>
            
            <h3>1.1 Without an account</h3>
            <p>By using the application without an account we only collect anonymous app usage and similar data.</p>
            
            <h3>1.2 With an account</h3>
            <p>By signing upp for an account we collect the following data:</p>
            <ul>
                <li>Name and nickname</li>
                <li>Email addresses</li>
                <li>Country of residence</li>
                <li>App usage and similar data</li>
            </ul>

            <h2>2. How can I change or delete the collected data?</h2>
            <p>To update or delete the collected data, please contact <a href="mailto:support@recipe-collection.net">support@recipe-collection.net</a>.</p>

            <h2>3. How do we use collected data?</h2>
            <p>We use the collected data for the following purposes:</p>
            <ul>
                <li>To create, sign-in to, and manage your account. If you sign in with a third-party social media account we will collect the required information for this purpose from the third-party service.</li>
                <li>To provide and maintain the service.</li>
                <li>To notify you of changes to our service.</li>
                <li>To comply with legal requests and laws.</li>
                <li>To monitor and improve the service. We may use your information for data usage analysis, and similar to evaluate and improve our services and marketing.</li>
            </ul>

            <h2>4. With who do we share the data?</h2>
            <p>We may share the collected data with third-parties for the above mentioned purposes. Below are the third-parties we may share data with, and for what purpose:</p>
            <ul>
                <li>Register and sign-in using Social media accounts, using a Google account, Facebook account, Twitter account, and/or Microsoft account.</li>
                <li>Microsoft Azure for service hosting, account management, cloud computing, storage, and authentication.</li>
                <li>Microsoft App Center and Application Insights for data usage, analytics, and service monitoring.</li>
            </ul>

            <h2>5. How long do we store the collected data?</h2>
            <p>The collected data will be stored for up to 90 days after you have terminated your account, unless otherwise required by law.</p>

            <h2>6. Updates to this privacy policy</h2>
            <p>We may update this policy. We might notify you or make a notification in our services for material changes. We encourage that you frequently review this privacy policy.</p>

            <h2>7. Contact</h2>
            <p>You can contact us by emailing <a href="mailto:support@recipe-collection.net">support@recipe-collection.net</a> if you have any questions regarding this policy.</p>
        </>
    )
}

export default PrivacyPolicy;