import * as React from 'react'
import Navigation from '../Navigation/Navigation'

const AuthenticatedShell: React.FunctionComponent = props => {
    return (
        <>
            <Navigation />
            <div className="page">
                {props.children}
            </div>
        </>
    );
}

export default AuthenticatedShell;