import * as React from 'react'
import './RecipePage.scss'
import { RouteComponentProps } from 'react-router';
import { Checkbox, Pivot, PivotItem, Spinner, SpinnerSize } from '@fluentui/react';
import { useRecipes } from '../Contexts/RecipesContext';
import { useImages } from '../Contexts/ImagesContext';
import HeaderedBox from '../Controls/HeaderedBox';

type PropsType = { id: string };

const RecipePage: React.FunctionComponent<RouteComponentProps<PropsType>> = props => {
    const recipesContext = useRecipes();
    const images = useImages();

    // TODO: The recipe can be undefined if going to this page by URL and the recipes has not yet been loaded. If that is the case set isLoading = true
    const [recipe, setRecipe] = React.useState(recipesContext.recipes.find(r => r.id === props.match.params.id));
    const [imageUrl, setImageUrl] = React.useState(''); // TODO: Set placeholder image

    React.useEffect(() => {
        // Update recipe if recipes changes or selected recipe
        setRecipe(recipesContext.recipes.find(r => r.id === props.match.params.id));
    }, [recipesContext.recipes, props.match.params.id])

    // Update authenticated URL when image url changes
    React.useEffect(() => {
        // TODO: Abort if navigating away before request finishes or if recipe changes
        const getAuthenticatedImageUrl = async () => {
            if (recipe && recipe.imageUrl && recipe.imageUrl.length > 0) {
                setImageUrl(await images.getAuthenticatedImageUrl(recipe.imageUrl));
            }
        };

        getAuthenticatedImageUrl();
    }, [recipe, images]);

    function isValidHttpUrl(str: string) {
        let url;

        try {
            url = new URL(str);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    if (recipe) {
        return (
            <div className="recipe" itemScope itemType="https://schema.org/Recipe">
                <div className="recipe-header">
                    <div className="recipe-background-image" style={{ backgroundImage: `url(${imageUrl})` }}>
                        <div className="overlay"></div>
                    </div>

                    <div className="recipe-image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
                    <div className="recipe-summary">
                        <h1 itemProp="name">{recipe.title}</h1>
                        <p itemProp="description">{recipe.description}</p>
                    </div>
                </div>

                <Pivot className="recipe-small-content">
                    <PivotItem headerText="Ingredients" className="ingredients-tab">
                        <p>{recipe.servings} servings.</p>
                        <ul className="ingredients-list">
                            {recipe.ingredients.map(i => <li itemProp="recipeIngredient">{i}</li>)}
                        </ul>
                    </PivotItem>
                    <PivotItem headerText="Instructions" className="instructions-tab">
                        <ul className="instructions-list" itemProp="recipeInstructions">
                            {recipe.instructions.map(i => <li><Checkbox label={i}></Checkbox></li>)}
                        </ul>
                        <h3>Source</h3>
                        {isValidHttpUrl(recipe.originalUrl) ? <a href={recipe.originalUrl} target="_blank" rel="noopener noreferrer">{recipe.originalUrl}</a> : <p>{recipe.originalUrl}</p>}
                    </PivotItem>
                </Pivot>

                <div className="recipe-large-content">
                    <HeaderedBox title="Ingredients" className="recipe-large-ingredients">
                        <p>{recipe.servings} servings.</p>
                        <ul className="ingredients-list">
                            {recipe.ingredients.map(i => <li itemProp="recipeIngredient">{i}</li>)}
                        </ul>
                    </HeaderedBox>
                    <HeaderedBox title="Instructions" className="recipe-large-instructions">
                        <ul className="instructions-list" itemProp="recipeInstructions">
                            {recipe.instructions.map(i => <li><Checkbox label={i}></Checkbox></li>)}
                        </ul>
                        <h3>Source</h3>
                        {isValidHttpUrl(recipe.originalUrl) ? <a href={recipe.originalUrl} target="_blank" rel="noopener noreferrer">{recipe.originalUrl}</a> : <p>{recipe.originalUrl}</p>}
                    </HeaderedBox>
                </div>
            </div>
        );
    }
    // TODO: This will show loading spinner if user has no recipes
    else if (!recipe && (recipesContext.recipes.length === 0 || recipesContext.isSyncing)) {
        return (
            <Spinner size={SpinnerSize.large} label="Loading recipe..." labelPosition="right" style={{ marginTop: '24px' }} />
        );
    } else {
        return <div>Recipe not found.</div>
    }
}

export default RecipePage;