import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import AboutPage from './AboutPage/AboutPage';
import AccountPage from './AccountPage/AccountPage';
import RecipesProvider from './Contexts/RecipesContext';
import AuthenticatedShell from './Layouts/AuthenticatedShell';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import RecipePage from './RecipePage/RecipePage';
import RecipesPage from './recipes/RecipesPage';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';

const AuthenticatedApp: React.FunctionComponent = () => {
    return (
        <RecipesProvider>
            <AuthenticatedShell>
                <Switch>
                    <Route path="/about" component={AboutPage} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/terms-and-conditions" component={TermsAndConditions} />

                    <Route path="/recipes/:id" component={RecipePage} />
                    <Route path="/account" component={AccountPage} />

                    <Route exact path="/" component={RecipesPage} />
                    <Route render={() => {
                        return (<div>Page not found.</div>)
                    }} />
                </Switch>
            </AuthenticatedShell>
        </RecipesProvider>
    );
};

export default AuthenticatedApp;