import * as React from 'react';
import './RecipeCard.scss'
import { ApiRecipe } from '../api/ApiRecipe';
import { useHistory } from 'react-router';
import { useImages } from '../Contexts/ImagesContext';

type PropsType = {
    recipe: ApiRecipe
}

const RecipeCard: React.FunctionComponent<PropsType> = (props) => {
    const history = useHistory();
    const images = useImages();
    const [authenticatedImageUrl, setAuthenticatedImageUrl] = React.useState('')

    // Update authenticated URL when image url changes
    React.useEffect(() => {
        // TODO: Abort if navigating away before request finishes or if recipe changes
        const getAuthenticatedImageUrl = async () => {
            if(props.recipe.imageUrl && props.recipe.imageUrl.length > 0) {
                setAuthenticatedImageUrl(await images.getAuthenticatedImageUrl(props.recipe.imageUrl));
            }
        };

        getAuthenticatedImageUrl();
    }, [props.recipe, images]);

    const onRecipeClick = () => {
        history.push('/recipes/' + props.recipe.id);
    }

    return (
        <div className="card" onClick={onRecipeClick}>
            <img src={authenticatedImageUrl} title={props.recipe.title} alt=""></img>
            <div className="content">
                <h4>{props.recipe.title}</h4>
                <p>{props.recipe.estimatedTime} minutes</p>
            </div>
        </div>
    );
}

export default RecipeCard;