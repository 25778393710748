import * as React from 'react'
import './Navigation.scss'
import { useHistory } from 'react-router-dom'
import { ActionButton, CommandBarButton, ContextualMenuItemType, IContextualMenuProps, Persona, PersonaSize, Pivot, PivotItem } from '@fluentui/react';
import { useAuth } from '../Contexts/AuthenticationContext';

export const Navigation: React.FunctionComponent = () => {
    const history = useHistory();
    const auth = useAuth();

    const onPivotItemClicked = (item : PivotItem | undefined) => {
        if(item && item.props.itemKey) {
            history.push(item.props.itemKey);
        }
    }

    const profileButtonMenuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: [
            { key: 'account', text: 'Account', iconProps: {iconName: "Contact"}, onClick: () => history.push('/account') },
            { key: 'sign-out', text: 'Sign out', iconProps: {iconName: "SignOut"}, onClick: () => auth.signOut() },
            { key: 'divider-1', itemType: ContextualMenuItemType.Divider },
            //{ key: 'settings', text: 'Settings', iconProps: {iconName: "Settings"}, onClick: () => history.push('/settings') },
            { key: 'about', text: 'About', iconProps: {iconName: "Info"}, onClick: () => history.push('/about') }
        ]};

    return (
        <div>
            <nav style={{display: "flex", height: "45px", background: "#eee", position: "fixed", width: "100%", zIndex: 10000}}>
                <div className="app-title">
                    Recipe Collection
                </div>
                <Pivot className="pivot" headersOnly={true} onLinkClick={onPivotItemClicked}>
                    <PivotItem headerText="Recipes" itemKey="/" />
                    {/*<PivotItem headerText="Schedule" itemKey="/schedule" />*/}
                </Pivot>
                <CommandBarButton className="profile-button" onRenderChildren={() => <Persona text={auth.userInfo?.name} size={PersonaSize.size32} />} menuProps={profileButtonMenuProps} />
                <ActionButton className="profile-button-small" onRenderChildren={() => <Persona size={PersonaSize.size32} hidePersonaDetails text={auth.userInfo?.name} />} menuProps={profileButtonMenuProps} />
            </nav>
            {/* Empty div just to occupy space at top */}
            <div style={{height: "45px", width: "100%"}}></div>
        </div>
    );
}

export default Navigation;