import * as React from 'react'
import './HeaderedBox.scss'

type PropsType = {
    title?: string,
    style?: React.CSSProperties,
    className?: string
}

const HeaderedBox: React.FunctionComponent<PropsType> = (props) => {
    return (
        <div className={props.className ? `headered-box ${props.className}` : 'headered-box'} style={props.style}>
            <header>
                <h2>{props.title}</h2>
            </header>

            <div className="content">
                {props.children}
            </div>
        </div>
    );
}

export default HeaderedBox;