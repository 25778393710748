import React, { FunctionComponent } from 'react';
import { useAuth } from '../Contexts/AuthenticationContext';

const AccountPage: FunctionComponent = () => {
    const auth = useAuth();

    return (
        <>
            <h1>User profile</h1>
            <p>You are signed in as <b>{auth.userInfo?.name}</b></p>

            <h2>Subscription</h2>
            <p>You have a free account, which is limited to max 100 online recipes.</p>
        </>
    );
}

export default AccountPage;