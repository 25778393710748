import { ApiSasToken } from "./ApiSasToken";
import { ApiSync } from "./ApiSync";

abstract class RecipesApi
{
    public static async sync(accessToken: string, since: Number, abortSignal?: AbortSignal) : Promise<ApiSync> {

        // TODO: Use env. variable for base URL
        const response = await fetch("https://recipe-collection-api.azurewebsites.net/api/v1/sync?since=" + since, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                signal: abortSignal
            });

        return await response.json();
    }

    public static async getSasToken(accessToken: string): Promise<ApiSasToken|null> {
        const response = await fetch("https://recipe-collection-api.azurewebsites.net/api/v1/users/me/storage-token", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        });

        if(response.ok) {
            return await response.json();
        }

        return null;
    }
};

export default RecipesApi;